"use strict";

/**
 * Created by Thiago Lima on 20/02/2020.
 */

(function () {
  'use strict';

  var contextCardLanguage = {
    header: {
      title: 'Defina o contexto da sua pesquisa',
      switchLabel: 'Monitorar buscas do contexto?',
      helper: {
        description: 'Ao inserir descritores no campo de Contexto você delimita o universo da sua pesquisa, quando deseja evitar conteúdos não relevantes ou ambíguos.',
        linkText: 'Saiba mais como utilizar o contexto.',
        link: "https://help.blip.ai/hc/pt-br/articles/18890292650647-Contexto-e-Grupos"
      }
    },
    body: {
      titleLabel: 'Título da pesquisa',
      helper: {
        description: "Use v\xEDrgula, ponto e v\xEDrgula ou pressione \u201Center\u201D para separar os descritores. Para aprimorar sua configura\xE7\xE3o e ampliar sua coleta,",
        linkText: "veja como utilizar os operadores.",
        link: "https://help.blip.ai/hc/pt-br/articles/18955282819735"
      }
    }
  };
  window.moduleExport({
    contextCardLanguage: contextCardLanguage
  }, typeof module !== 'undefined' && module);
})();